import { FC, memo } from 'react';
import { IconComponent } from '@app/types';
import { COLORS } from '@app/constants';

const SvgLogoOnlineAll: FC<IconComponent> = ({
  color = COLORS.grayscale600,
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={175}
      height={53}
      viewBox="0 0 175 53"
      fill="none"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          d="M19.5964 19.8467H3.93199H0V42.9323H3.93199V23.4049H19.5964V42.9323H23.5283V19.8467H19.5964Z"
          fill="#001F1B"
        />
        <path
          d="M23.8779 34.41C23.8779 28.3909 27.3118 24.6196 33.8934 24.6196C40.4961 24.6196 43.8982 28.3909 43.8982 34.41C43.8982 40.3971 40.4961 44.1258 33.8934 44.1258C27.3118 44.1258 23.8779 40.3865 23.8779 34.41ZM40.3584 34.41C40.3584 30.0102 37.9207 27.8689 33.8828 27.8689C29.8766 27.8689 27.439 30.0208 27.439 34.41C27.439 38.7459 29.8766 40.8872 33.8828 40.8872C37.9101 40.8872 40.3584 38.7352 40.3584 34.41Z"
          fill="#001F1B"
        />
        <path
          d="M54.6875 34.41C54.6875 28.3909 58.1214 24.6196 64.6923 24.6196C71.2951 24.6196 74.6972 28.3909 74.6972 34.41C74.6972 40.3971 71.2951 44.1258 64.6923 44.1258C58.1214 44.1258 54.6875 40.3865 54.6875 34.41ZM71.1679 34.41C71.1679 30.0102 68.7303 27.8689 64.6923 27.8689C60.6862 27.8689 58.2485 30.0208 58.2485 34.41C58.2485 38.7459 60.6968 40.8872 64.6923 40.8872C68.7197 40.8872 71.1679 38.7352 71.1679 34.41Z"
          fill="#001F1B"
        />
        <path
          d="M91.9199 34.41C91.9199 28.3909 95.3538 24.6196 101.935 24.6196C108.538 24.6196 111.94 28.3909 111.94 34.41C111.94 40.3971 108.538 44.1258 101.935 44.1258C95.3644 44.1258 91.9199 40.3865 91.9199 34.41ZM108.4 34.41C108.4 30.0102 105.963 27.8689 101.925 27.8689C97.9186 27.8689 95.481 30.0208 95.481 34.41C95.481 38.7459 97.9186 40.8872 101.925 40.8872C105.963 40.8872 108.4 38.7352 108.4 34.41Z"
          fill="#001F1B"
        />
        <path
          d="M91.8244 38.3407C91.8244 41.5048 89.0582 43.1667 84.9355 43.1667H75.2168V25.5781H84.2148C88.062 25.5781 90.8282 27.1655 90.8282 30.0525C90.8282 32.066 89.4186 33.877 86.8432 34.0475C89.5564 34.2286 91.8244 35.4857 91.8244 38.3407ZM78.7355 32.801H83.3457C85.6986 32.801 87.2671 32.4601 87.2671 30.5319C87.2671 28.6037 85.6986 28.4758 83.3457 28.4758H78.7355V32.801ZM88.1786 38.0424C88.1786 36.0609 86.5994 35.6242 83.9816 35.6242H78.746V40.4607H83.9816C86.5994 40.4607 88.1786 40.024 88.1786 38.0424Z"
          fill="#001F1B"
        />
        <path
          d="M44.3438 43.1667V25.5781H51.4446H56.0019V28.4758H47.873V36.4338V43.1667H44.3438Z"
          fill="#001F1B"
        />
        <path
          d="M116.094 39.8433V49.3673H112.374V25.4507H114.324L116.094 28.5828C117.588 26.3776 119.634 24.8115 123.354 24.8115C129.013 24.8115 132.765 28.5189 132.765 34.2077C132.765 39.8646 129.013 43.6039 123.354 43.6039C119.634 43.6145 117.588 42.0485 116.094 39.8433ZM116.094 33.4833V34.9641C116.497 38.991 119.507 40.4186 122.4 40.4186C126.215 40.4186 129.141 38.2133 129.141 34.2184C129.141 30.2234 125.94 27.9862 122.4 27.9862C119.507 27.9755 116.55 29.8505 116.094 33.4833Z"
          fill="#001F1B"
        />
        <path
          d="M152.828 25.6636V43.1775H150.507L149.108 39.7898C147.582 42.2827 144.721 43.8167 141.16 43.8167C135.85 43.8167 133.211 40.397 133.211 35.667V25.6636H136.899V35.4113C136.899 38.863 138.648 40.7166 142.114 40.7166C145.102 40.7166 147.614 39.3104 149.108 36.434V25.6636H152.828Z"
          fill="#001F1B"
        />
        <path
          d="M172.732 25.6421V43.1987H169.362V30.6811L161.052 42.7086H160.872L152.563 30.4893V43.1987H150.698L149.299 39.8003V25.6421H152.913L161.021 37.5951L169.128 25.6421H172.732Z"
          fill="#001F1B"
        />
        <path
          d="M164.815 49.367C164.815 51.3805 163.193 53.0105 161.19 53.0105C159.187 53.0105 157.565 51.3805 157.565 49.367C157.565 47.3536 159.187 45.7236 161.19 45.7236C163.193 45.7236 164.815 47.3536 164.815 49.367Z"
          fill="#B8362D"
        />
        <path
          d="M175 49.367C175 51.3805 173.379 53.0105 171.376 53.0105C169.373 53.0105 167.751 51.3805 167.751 49.367C167.751 47.3536 169.373 45.7236 171.376 45.7236C173.379 45.7236 175 47.3536 175 49.367Z"
          fill="#001F1B"
        />
        <path
          d="M154.619 49.367C154.619 51.3805 152.998 53.0105 150.995 53.0105C148.992 53.0105 147.37 51.3805 147.37 49.367C147.37 47.3536 148.992 45.7236 150.995 45.7236C152.998 45.7236 154.619 47.3536 154.619 49.367Z"
          fill="#64AB8A"
        />
        <path
          d="M161.275 9.30029H162.992V14.7335H171.015V9.30029H172.732V22.4997H171.015V16.2249H162.992V22.4997H161.275V9.30029Z"
          fill="#001F1B"
        />
        <path
          d="M112.3 9.30029H114.017V14.7335H122.04V9.30029H123.757V22.4997H122.04V16.2249H114.017V22.4997H112.3V9.30029Z"
          fill="#001F1B"
        />
        <path
          d="M151.059 22.3718C150.274 22.5848 149.935 22.6381 149.469 22.6381C148.345 22.6381 147.752 21.8817 147.752 20.4009C146.035 22.2652 144.583 22.8831 142.008 22.8831C138.839 22.8831 136.878 21.4023 136.878 19.0266C136.878 17.4606 137.577 16.3846 139.029 15.7348C139.697 15.4365 140.619 15.2234 142.304 15.0103L145.049 14.6375C147.116 14.3392 147.731 13.9344 147.731 12.8264C147.731 11.2924 146.363 10.4721 143.83 10.4721C140.768 10.4721 139.369 11.3882 139.157 13.5828H137.408C137.62 12.1553 137.895 11.4522 138.531 10.749C139.633 9.44935 141.213 8.93799 143.905 8.93799C146.3 8.93799 147.826 9.4813 148.684 10.6638C149.278 11.4522 149.384 12.0168 149.384 14.0622V20.0067C149.384 20.7951 149.649 21.1679 150.242 21.1679C150.433 21.1679 150.677 21.1147 151.048 21.0081V22.3718H151.059ZM147.752 16.2674C147.752 15.8093 147.784 15.6176 147.805 15.1382C147.105 15.6282 146.565 15.8093 144.742 16.1076L142.294 16.4805C139.443 16.9386 138.584 17.5032 138.584 18.9094C138.584 20.5074 139.93 21.4236 142.241 21.4236C144.148 21.4236 145.844 20.7205 146.999 19.4208C147.561 18.7496 147.72 18.3981 147.72 17.6417L147.752 16.2674Z"
          fill="#001F1B"
        />
        <path
          d="M111.781 16.0222C111.781 19.9639 108.803 22.8829 104.744 22.8829C100.61 22.8829 97.7275 20.0492 97.7275 15.969C97.7275 11.7822 100.631 8.9165 104.818 8.9165C108.93 8.92716 111.781 11.8142 111.781 16.0222ZM99.4445 15.8944C99.4445 19.0797 101.67 21.3489 104.818 21.3489C107.796 21.3489 110.032 19.0584 110.032 15.9796C110.032 12.7623 107.86 10.4719 104.797 10.4719C101.702 10.4612 99.4445 12.7623 99.4445 15.8944Z"
          fill="#001F1B"
        />
        <path
          d="M131.494 9.30029L137.514 22.5103H135.638L131.822 14.0303C131.526 13.3592 131.282 12.7839 130.561 10.9196C129.968 12.5176 129.671 13.274 129.3 14.1369L125.59 22.5103H123.768L129.703 9.30029H131.494Z"
          fill="#001F1B"
        />
        <path
          d="M161.413 22.5103V19.7298C160.798 20.6779 160.395 21.104 159.717 21.5621C158.647 22.3185 157.11 22.7553 155.584 22.7553C152.224 22.7553 150.528 21.1892 150.528 18.0785V9.3002H152.277V17.695C152.277 20.1559 153.432 21.2638 155.986 21.2638C158.159 21.2638 160.046 20.2944 160.872 18.7497C161.137 18.2383 161.275 17.5884 161.275 16.7468V9.28955H162.992V22.4996H161.413V22.5103Z"
          fill="#001F1B"
        />
      </g>
      <defs>
        <clipPath id="a">
          <rect width="175" height="53" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const Memo = memo(SvgLogoOnlineAll);
export default Memo;
